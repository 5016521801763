 <template>
	<el-dialog title="收货地址" top="20vh"  width="500px" :visible.sync="is_show_in_page" :modal="false" @close ="close">
        <div class="center">
             <el-button @click="add_addr()">新增收货地址</el-button>
            <div class="item" v-for="(item,index) in addr_list" :key="index" @mouseenter="onMouseOver(item)"  @mouseleave="onMouseOut(item)">
                <div class="left">
                   <div class="name">
                       收货人：{{item.name}}
                   </div>
                    <div class="tel">
                       手机号码：{{item.tel}}
                   </div>
                   <div class="addr">
                       收货地址：{{item.addr_info}}
                   </div>
                </div>
                <div class="right">
                   <div class="name" v-if="item.is_default ==1">
                      默认地址
                   </div>
                    <div class="operate" v-if="item.is_show">
                        <div class="delete" @click="addr_delete(item.id)">
                            删除
                        </div>
                         <div class="set" @click="set_is_default(item.id)">
                            设为默认
                        </div>
                   </div>
                </div>
            </div>
        </div>
    <el-dialog title="添加地址" top="25vh"  width="400px" :visible.sync="invoice_addr.is_show" :modal="false">
        <div class="center">
            <div class="item1">
                <el-form class="ser_form" size="small"  label-width="68px">
                    <el-form-item class="el_form_item" label="收货人">
                        <el-input class="el_input" v-model="invoice_addr.name" placeholder="收货人" clearable></el-input>
                    </el-form-item>
                    <el-form-item class="el_form_item" label="手机号码">
                        <el-input class="el_input" v-model="invoice_addr.tel" placeholder="手机号码" clearable></el-input>
                    </el-form-item>
                  <div class="mark">
                    <div style="width:100px;margin-top:25px;margin-left:5px;">详细地址</div>
                    <el-input placeholder="请输入详细地址" type="textarea"  v-model="invoice_addr.addr" style="margin-top:10px;">
                    </el-input>
                </div>
                </el-form>
            </div>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="addr_confirm">确 定</el-button>
        </div>
	</el-dialog>
	</el-dialog>
</template>

<script>
	export default {
        props:{
			is_show:Number,
		},
		data() {
			return {
              addr_list:[],//地址列表
			  is_show_in_page:false,
              invoice_addr:{
                  name:'',//添加人姓名
                  tel:"", //添加人手机号
                  addr:'',//详细地址
                  is_show:false,
              }
			}
		},
		watch:{
			is_show(new_data){
                if(new_data){
                    this.init()
                    this.is_show_in_page=true
                }
			},
		},
		computed:{
		},
		methods: {
            //弹窗关闭
            close(){
                this.$emit('close')
            },
            //地址删除
            addr_delete(id){
                 this.$my.net.req({
                    data:{
                        mod:'truck_tord_real_time',
                        ctr:'invoice_address_del',
                        id,
                    },callback:(data)=>{
                        //提醒
                        this.$my.other.msg({
                            str:'删除成功',
                            type:'success'
                        });

                        //刷新
                        this.init()
                    }
                })
            },
              // 鼠标移入
            onMouseOver(item){
                item.is_show = true
               
            },
            // 鼠标移出
            onMouseOut(item){
                item.is_show = false
            },

            //设未默认
            set_is_default(id){
                 this.$my.net.req({
                    data:{
                        mod:'truck_tord_real_time',
                        ctr:'invoice_address_set',
                        is_default:1,
                        id,
                    },callback:(data)=>{
                        //提醒
                        this.$my.other.msg({
                            str:'操作成功',
                            type:'success'
                        });

                        //刷新
                        this.init()
                    }
                })
            },
            //确认添加地址
            addr_confirm(){
                if(!this.invoice_addr.name){
                    this.$my.other.msg({
                        type:'warning',
                        str:"请填写收货人姓名"
					});
                }
                if(!this.invoice_addr.tel){
                    this.$my.other.msg({
                        type:'warning',
                        str:"请填写收货人电话"
					});
                }
                if(!this.invoice_addr.addr){
                    this.$my.other.msg({
                        type:'warning',
                        str:"请填写收货人地址"
					});
                }
                this.$my.net.req({
                    data:{
                        mod:'truck_tord_real_time',
                        ctr:'invoice_address_add',
                        name:this.invoice_addr.name,
                        tel:this.invoice_addr.tel,
                        addr_info:this.invoice_addr.addr
                    },callback:(data)=>{
                        //提醒
                        this.$my.other.msg({
                            str:'操作成功',
                            type:'success'
                        });
                        //关闭弹窗
                        this.invoice_addr.is_show = false

                        //刷新
                        this.init()
                    }
                })
            },
            //初始化
            init(){
               this.$my.net.req({
                    data:{
                        mod:'truck_tord_real_time',
                        ctr:'invoice_address_list',
                    },callback:(data)=>{
                        if(data.address_list){
                            for(let item of data.address_list){
                                    item.is_show = false
                                }
                            this.addr_list = data.address_list
                        }else{
                            this.addr_list = []
                        }


                    }
                })
            },
            //新增收货地址
            add_addr(){
                this.invoice_addr.name = ''
                this.invoice_addr.tel = ''
                this.invoice_addr.addr = ''
                this.invoice_addr.is_show = true
            },
		}
	}
</script>
<style lang="scss" scoped>
.mark{
    display:flex;
     margin-left: -10px;
     margin-top: 20px;
}
.center {
    height: 200px;
    .item{
        margin-top: 20px;
        display:flex;
        .name{
            margin-right: 10px;
        }
    }
    
}
.right{
    width:120px;
}
.operate{
    margin-top:10px;
    display:flex;
    .delete{
        color:red;
        cursor:pointer;
    }
    .set{
        color:#409EFF;
        margin-left:10px;
         cursor:pointer;
    }
}
.item{
    weight:80%;
    height:120px;
    border:1px dashed #cccccc
}
.item1{
    weight:80%;
    height:200px;
    padding:10px 20px
}
.left{
    width:60%;
    padding: 10px 20px;
    .name{
        margin-top:10px;
    }
    .tel{
        margin-top:10px;
    }
    .addr{
         margin-top:10px;
    }
}
.right{
    padding: 10px 20px;
    .name{
        margin-top:10px;
    }
    .tel{
        margin-top:10px;
    }
    .addr{
         margin-top:10px;
    }
}
</style>